import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'AUTH_FETCH',
  'AUTH_RESET',
  'AUTH_LOAD_SUCCESS',
  'AUTH_LOAD_FAILURE',
  'BOOKING_FETCH',
  'BOOKING_RESET',
  'BOOKING_LOAD_SUCCESS',
  'BOOKING_LOAD_FAILURE',
  'BOOKING_APPEND_SUCCESS',
  'BOOKING_APPEND_FAILURE',
  'BOOKING_SET_DISPLAY_COLLECTION_START_DATE',
  'MESSAGE_PUSH',
  'MESSAGE_CLOSE',
])

export default actionTypes;
