import Immutable from 'immutable'
import actionTypes from '../constants/saam_constants'

export const $$initialState = Immutable.fromJS({
  messages: [],
  message: ''
})

export default function messageReducer($$state = $$initialState, action) {

  const { type, payload, error } = action
  if (error) {
    return $$state
  }
  switch (type) {
    case actionTypes.MESSAGE_PUSH:
      return push($$state, payload)
    case actionTypes.MESSAGE_CLOSE:
      return close($$state, payload)
    default:
      return $$state
  }
}

function push($$state, payload) {
  let $$messages = $$state.get('messages')
  $$messages = $$messages.set($$messages.size, payload)
  let message = $$state.get('message')
  if (!message) {
    message = $$messages.first()
    $$messages = $$messages.shift()
  }
  return $$state.merge({
    messages: $$messages,
    message: message || ''
  })
}

function close($$state, payload) {
  let $$messages = $$state.get('messages')
  let message = $$messages.first()
  $$messages = $$messages.shift()
  return $$state.merge({
    messages: $$messages,
    message: message || ''
  })
}


