import axios from 'axios';
import qs from 'qs';

const apiUrl = process.env.API_URL;

class Api {
  static headers(token) {
    if (token && token !== undefined) {
      return {
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${token}`,
      };
    } else {
      return {
        'X-Requested-With': 'XMLHttpRequest',
      };
    }
  }

  static get(route, data = null) {
    return this.xhr(route, data, 'get');
  }

  static put(route, data) {
    return this.xhr(route, data, 'put');
  }

  static post(route, data) {
    return this.xhr(route, data, 'post');
  }

  static delete(route, data) {
    return this.xhr(route, data, 'delete');
  }

  static xhr(route, data, verb) {
    let newRoute = route;
    let newData = data;

    if (!newRoute.match(/^http/)) {
      newRoute = `${apiUrl}${newRoute}.json`;
    }
    if (newData) {
      if (verb === 'get' && Object.keys(newData).length > 0) {
        newRoute = `${newRoute}?${qs.stringify(newData, {
          indices: false,
          arrayFormat: 'brackets',
        })}`;
      }
    } else {
      newData = {};
    }
    return axios({
      method: verb,
      url: newRoute,
      headers: this.headers(localStorage.getItem('token')),
      data: newData,
    });
  }
}

export default Api;
