import Immutable from 'immutable'
import actionTypes from '../constants/saam_constants'

export const $$initialState = Immutable.fromJS({
  isAuthenticated: false,
  isAuthenticating: false,
  token: "",
  provider: "",
  provider_id: ""
})

export default function authReducer($$state = $$initialState, action) {

  const { type, payload, error } = action
  if (error) {
    return $$state
  }
  switch (type) {
    case actionTypes.AUTH_FETCH:
      return fetch($$state, payload)
    case actionTypes.AUTH_LOAD_SUCCESS:
      return load_success($$state, payload)
    case actionTypes.AUTH_LOAD_FAILURE:
      return load_failure($$state, payload)
    case actionTypes.AUTH_RESET:
      return reset()
    default:
      return $$state
  }
}

function reset () {
  return $$initialState
}

function fetch($$state, payload) {
  return $$state.merge({
    isAuthenticating: true
  })
}

function load_success($$state, payload) {
  return $$state.merge({
    isAuthenticating: false,
    isAuthenticated: true,
    token: payload.token,
    provider: payload.provider,
    provider_id: payload.provider_id
  })
}

function load_failure($$state, payload) {
  return $$state.merge({
    isAuthenticating: false,
    isAuthenticated: false,
    token: "",
    provider: "",
    provider_id: ""
  })
}
