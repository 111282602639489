import Immutable from 'immutable'
import actionTypes from '../constants/saam_constants'
import moment from 'moment'
moment.locale('en-nz')

export const $$initialState = Immutable.fromJS({
  isFetching: false,
  collection: [],
  collectionStartDate: moment().startOf('month').toISOString(),
  collectionEndDate: moment().add(1, 'month').endOf('month').toISOString(),
  displayCollectionStartDate: moment().startOf('month').toISOString()
})

export default function bookingsReducer($$state = $$initialState, action) {

  const { type, payload, meta, error } = action
  if (error) {
    return $$state
  }
  switch (type) {
    case actionTypes.BOOKING_RESET:
      return reset()
    case actionTypes.BOOKING_FETCH:
      return $$state.merge({isFetching: true})
    case actionTypes.BOOKING_LOAD_SUCCESS:
      return load_success($$state, payload)
    case actionTypes.BOOKING_LOAD_FAILURE:
      return load_failure($$state, payload)
    case actionTypes.BOOKING_APPEND_SUCCESS:
      return append_success($$state, payload, meta)
    case actionTypes.BOOKING_APPEND_FAILURE:
      return append_failure($$state, payload)
    case actionTypes.BOOKING_SET_DISPLAY_COLLECTION_START_DATE:
      return setDisplayCollectionStartDate($$state, payload)
    default:
      return $$state
  }
}

function reset () {
  return $$initialState
}

function load_success ($$state, payload) {
  return $$initialState.merge({
    collection: Immutable.fromJS(payload)
  })
}

function load_failure ($$state, payload) {
  return reset()
}

function append_success ($$state, payload, meta) {
  const $$collection = $$state.get('collection')
  return $$state.merge({
    collection: Immutable.fromJS(payload).concat($$collection),
    collectionEndDate: meta.collectionEndDate,
    isFetching: false
  })
}

function append_failure ($$state, payload) {
  return $$state
}

function setDisplayCollectionStartDate($$state, payload) {
  return $$state.merge({displayCollectionStartDate: payload.displayCollectionStartDate})
}
