import bookingsReducer, { $$initialState as $$bookingsInitialState } from './bookings_reducer'
import authReducer, { $$initialState as $$authInitialState } from './auth_reducer'
import messageReducer, { $$initialState as $$messageInitialState } from './message_reducer'

export const reducers = {
  $$bookings: bookingsReducer,
  $$auth: authReducer,
  $$message: messageReducer,
}

export const initialStates = {
  $$bookingsInitialState,
  $$authInitialState,
  $$messageInitialState,
}
