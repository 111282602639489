import React from 'react'
import {Route, IndexRoute, IndexRedirect} from 'react-router';
import App from '../containers/app'
import BookingList from '../containers/booking_list'
import Login from '../containers/login'
import { routerActions } from 'react-router-redux'
import { UserAuthWrapper } from 'redux-auth-wrapper'

const requireAuthentication = UserAuthWrapper({
  authSelector: state => state.$$auth.toJS(),
  predicate: auth => auth.isAuthenticated,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsJWTAuthenticated'
})

export default () => {
  return (
    <Route path='/' component={App}>
      <IndexRedirect to="/bookings" />
      <Route path="/login" component={Login}/>
      <Route path="/bookings" component={requireAuthentication(BookingList)}/>
    </Route>
  )
}
