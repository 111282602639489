import actionTypes from '../constants/saam_constants';
import Api from '../lib/api'
import jwtDecode from 'jwt-decode'
import { push } from 'react-router-redux'

//
// Login and logout actions
//
export function storeLoad (provider_id) {
  return function(dispatch) {
    dispatch(bookingsLoad(provider_id))
  }
}

export function storeReset () {
  return function(dispatch) {
    dispatch(bookingsReset())
  }
}

//
// Bookings
//
export function bookingsFetch() {
  return {
    type: actionTypes.BOOKING_FETCH
  }
}

export function bookingsReset() {
  return {
    type: actionTypes.BOOKING_RESET
  }
}

export function bookingsSetDisplayCollectionStartDate(props) {
  return {
    type: actionTypes.BOOKING_SET_DISPLAY_COLLECTION_START_DATE,
    payload: props
  }
}

export function bookingsLoad (provider_id) {
  return function(dispatch) {
    dispatch(bookingsFetch())
    return Api.get('/api/providers/' + provider_id + '/bookings')
      .then(res => {
        dispatch(bookingsLoadSuccess(res.data))
      })
      .catch(res => {
        let err = {...res}.response
        if (err.status && err.status === 401) {
          dispatch(authLoadFailure())
          dispatch(push('/login'))
        } else {
          dispatch(messagePush(err.data.message || "Load unsuccessful"))
          dispatch(bookingsLoadFailure())
        }
      })
  }
}

export function bookingsLoadSuccess(data) {
  return {
    type: actionTypes.BOOKING_LOAD_SUCCESS,
    payload: data
  }
}

export function bookingsLoadFailure() {
  return {
    type: actionTypes.BOOKING_LOAD_FAILURE
  }
}

export function bookingsAppend (props) {
  return function(dispatch) {
    dispatch(bookingsFetch())
    return Api.get('/api/providers/' + props.provider_id + '/bookings', { start_at: props.start_at, end_at: props.end_at})
      .then(res => {
        dispatch(bookingsAppendSuccess(res.data, props))
      })
      .catch(res => {
        let err = {...res}.response
        if (err.status && err.status === 401) {
          dispatch(authLoadFailure())
          dispatch(push('/login'))
        } else {
          dispatch(messagePush(err.data.message || "Load unsuccessful"))
          dispatch(bookingsAppendFailure())
        }
      })
  }
}

export function bookingsAppendSuccess (data, props) {
  return {
    type: actionTypes.BOOKING_APPEND_SUCCESS,
    payload: data,
    meta: props
  }
}

export function bookingsAppendFailure() {
  return {
    type: actionTypes.BOOKING_APPEND_FAILURE
  }
}

//
//Authentication
//
export function authFetch() {
  return {
    type: actionTypes.AUTH_FETCH
  }
}

export function authReset() {
  return function(dispatch) {
    localStorage.removeItem('token')
    dispatch(storeReset())
    dispatch({
      type: actionTypes.AUTH_RESET
    })
  }
}

export function authLoad (username, password, redirect="/") {
  return function(dispatch) {
    dispatch(authFetch())
    return Api.post('/login', {username, password})
      .then(res => {
        try {
          let decoded = jwtDecode(res.data.token)
          dispatch(authLoadSuccess(res.data.token, redirect))
          dispatch(messagePush(res.data.message || "Login Successful"))
        } catch (e) {
          dispatch(authLoadFailure())
          dispatch(messagePush("Invalid Token"))
        }
      })
      .catch(res => {
        let err = {...res}.response
        dispatch(authLoadFailure())
        dispatch(messagePush(err.data.message || "Login Unsuccessful"))
      })
  }
}

export function authLoadFailure() {
  localStorage.removeItem('token')
  return {
    type: actionTypes.AUTH_LOAD_FAILURE
  }
}

export function authLoadSuccess(token, redirect="/") {
  return function(dispatch) {
    try {
      let decoded = jwtDecode(token)
      localStorage.setItem('token', token)
      dispatch({
        type: actionTypes.AUTH_LOAD_SUCCESS,
        payload: {
          token: token,
          provider: decoded.provider,
          provider_id: decoded.provider_id
        }
      })
      dispatch(storeLoad(decoded.provider_id))
      dispatch(push(redirect))
    } catch (e) {
      dispatch(authLoadFailure())
      dispatch(messagePush("Invalid Token"))
    }
  }
}

//
// Messages
//
export function messagePush(message) {
  return {
    type: actionTypes.MESSAGE_PUSH,
    payload: message
  }
}

export function messageClose() {
  return {
    type: actionTypes.MESSAGE_CLOSE
  }
}
