import { createSelector } from 'reselect'
import moment from 'moment'
moment.locale('en-nz')

const $$bookingsCollection = (state) => state.$$bookings.get('collection')
const bookingsDisplayCollectionStartDate = (state) => state.$$bookings.get('displayCollectionStartDate')

export const getBookingsDisplayCollection = createSelector(
  [$$bookingsCollection, bookingsDisplayCollectionStartDate],
  ($$collection, start) => {
    const MM_start = moment(start)
    const MM_end = MM_start.clone().add(1, 'month').endOf('month')
    return $$collection
      .filter( $$model => {
        return moment($$model.get('start_at')).isSameOrBefore(MM_end) && moment($$model.get('end_at')).isSameOrAfter(MM_start)
       })
      .sort((a, b) => {return a.get('start_at').localeCompare(b.get('start_at'))})
  }
)

