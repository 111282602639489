import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Immutable from 'immutable';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

import Avatar from 'material-ui/Avatar';
import ActionFlightTakeoffIcon from 'material-ui/svg-icons/action/flight-takeoff';
import Chip from 'material-ui/Chip';
import { Card, CardHeader, CardTitle, CardText, CardActions } from 'material-ui/Card';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';
import DateTime from 'react-datetime';
import Spinner from 'react-spinner';

import get from 'lodash/get';
import compact from 'lodash/compact';
import includes from 'lodash/includes';
import _intersection from 'lodash/intersection';

import NestedTable from './nested_table';

import { getBookingsDisplayCollection } from '../selectors/bookings_selectors';

import {
  bookingsAppend,
  bookingsSetDisplayCollectionStartDate,
} from '../actions/saam_actions';

require('react-spinner/react-spinner.css');
require('../css/react-spinner-dark.css');
require('react-datetime/css/react-datetime.css');

const moment = extendMoment(Moment);
moment.locale('en-nz');
const beginningOfMonth = moment().tz('Pacific/Auckland').startOf('month');

const showBlockedProviders = {
  139: [10, 54], // uppercase provider_id (139), HXW Old (aircraft_id 10), HXW (aircraft_id 54) only
  // 139: [10, 8], // uppercase provider_id (139), HXW (aircraft_id 10), IXW (aircraft_id 8) only
  // 141: [13] // kc charters provider_id (141), IKC (aircraft_id 13) only
};

const showRelatedChargeables = {
  147: [232, 643], // tara iti #1, (147), Golf course (chargeable_id 232), Holdings (chargeable_id 643) only
};

class BookingList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      start_at_date:
        this.props.MM_bookingsDisplayCollectionStartDate.tz('Pacific/Auckland').format(
          'MM/YYYY'
        ),
      start_at_date_error: '',
    };
    this._handleStartAtDateChange = this._handleStartAtDateChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      start_at_date:
        nextProps.MM_bookingsDisplayCollectionStartDate.tz('Pacific/Auckland').format(
          'MM/YYYY'
        ),
      start_at_date_error: '',
    });
  }

  _handleStartAtDateChange(date) {
    if (!this.props.bookingsIsFetching) {
      if (moment.isMoment(date)) {
        this.setState({
          start_at_date: date.tz('Pacific/Auckland').format('MM/YYYY'),
          start_at_date_error: '',
        });
        this.props.bookingsSetDisplayCollectionStartDate({
          displayCollectionStartDate: date.clone().startOf('month').clone().toISOString(),
        });
        if (
          this.props.MM_bookingsCollectionEndDate.isSameOrBefore(
            date.clone().tz('Pacific/Auckland').add(1, 'month')
          )
        ) {
          const MM_endDate = moment.max(
            date.clone().tz('Pacific/Auckland').add(1, 'month').endOf('month'),
            this.props.MM_bookingsCollectionEndDate.clone()
              .tz('Pacific/Auckland')
              .add(1, 'month')
          );
          this.props.bookingsAppend({
            provider_id: this.props.auth_provider_id,
            collectionEndDate: MM_endDate.tz('Pacific/Auckland').toISOString(),
            start_at: this.props.MM_bookingsCollectionEndDate.clone()
              .tz('Pacific/Auckland')
              .add(1, 'day')
              .startOf('day')
              .toISOString(),
            end_at: MM_endDate.tz('Pacific/Auckland').toISOString(),
          });
        }
      } else {
        this.setState({
          start_at_date_error: 'MM/YYYY',
        });
      }
    }
  }

  _isValidDate(current) {
    return current.isSameOrAfter(beginningOfMonth);
  }

  _renderHeaderDate(model, dayStart) {
    const { start_at, end_at } = model;
    const response = moment
      .max(moment(start_at).tz('Pacific/Auckland'), dayStart.tz('Pacific/Auckland'))
      .format('ddd, DD MMM YYYY');
    return <span>{response}</span>;
  }

  _renderHeaderTime(model, dayStart) {
    const { calendar_type, start_at, end_at } = model;
    if (calendar_type !== 'banner') {
      let response;
      if (
        moment(start_at)
          .tz('Pacific/Auckland')
          .isSame(moment(end_at).tz('Pacific/Auckland'), 'day')
      ) {
        response =
          moment(start_at).tz('Pacific/Auckland').format('hh:mma') +
          '-' +
          moment(end_at).tz('Pacific/Auckland').format('hh:mma');
      } else {
        let start;
        let end;
        if (
          moment(start_at)
            .tz('Pacific/Auckland')
            .isSame(dayStart.tz('Pacific/Auckland'), 'day')
        ) {
          start = moment(start_at).tz('Pacific/Auckland').format('hh:mma');
        } else {
          start = dayStart.tz('Pacific/Auckland').format('hh:mma');
        }
        if (
          moment(end_at)
            .tz('Pacific/Auckland')
            .isSame(dayStart.tz('Pacific/Auckland'), 'day')
        ) {
          end = moment(end_at).tz('Pacific/Auckland').format('hh:mma');
        } else {
          end = dayStart.tz('Pacific/Auckland').clone().endOf('day').format('hh:mma');
        }
        response = start + ' - ' + end;
      }

      return <span>{response}</span>;
    }
  }

  _renderHeaderTitle(model, dayStart) {
    return (
      <span>
        <span style={{ paddingRight: '0.5em' }}>
          {this._renderHeaderDate(model, dayStart)}
        </span>
        <span style={{ paddingRight: '0.5em' }}>
          {this._renderHeaderTime(model, dayStart)}
        </span>
      </span>
    );
  }

  _renderColoredHumanStatus(human_status) {
    switch (human_status) {
      case 'Tentative':
        return <span style={{ color: 'red' }}>{human_status}</span>;
      case 'Confirmed':
        return <span style={{ color: 'green' }}>{human_status}</span>;
      default:
        return human_status;
    }
  }

  _renderHeaderSubtitleChargeable(model, withProvider) {
    const { chargeable_full_names, provider_full_name } = model;
    if (withProvider) {
      return `${chargeable_full_names} (by ${provider_full_name})`;
    }
    return `${chargeable_full_names}`;
  }

  _renderHeaderSubtitle(model, withProvider) {
    const { human_status, aircraft_display_name } = model;
    return (
      <span>
        {this._renderHeaderSubtitleChargeable(model, withProvider)}
        <br />
        {aircraft_display_name + ' - '}
        {this._renderColoredHumanStatus(human_status)}
      </span>
    );
  }

  _renderHeaderMaintenanceSubtitle(model) {
    const { aircraft_display_name } = model;
    if (aircraft_display_name === 'HXW') {
      return <span>{aircraft_display_name + ' - Not Available'}</span>;
    }
    return <span>{aircraft_display_name + ' - Maintenance'}</span>;
  }

  _renderHeaderBlockedSubtitle(model) {
    const { human_status, aircraft_display_name } = model;
    if (aircraft_display_name === 'HXW') {
      return <span>{aircraft_display_name + ' - Not Available'}</span>;
    }
    return <span>{aircraft_display_name + ' - Commercial Booking - Not Available'}</span>;
  }

  _renderMaintenanceModel(model, index, dayStart) {
    return (
      <div key={index} style={{ padding: '10px' }}>
        <Card>
          <CardHeader
            title={this._renderHeaderTitle(model, dayStart)}
            subtitle={this._renderHeaderMaintenanceSubtitle(model)}
            avatar={<Avatar icon={<ActionFlightTakeoffIcon />} />}
          />
        </Card>
      </div>
    );
  }

  _renderBlockedModel(model, index, dayStart) {
    return (
      <div key={index} style={{ padding: '10px' }}>
        <Card>
          <CardHeader
            title={this._renderHeaderTitle(model, dayStart)}
            subtitle={this._renderHeaderBlockedSubtitle(model)}
            avatar={<Avatar icon={<ActionFlightTakeoffIcon />} />}
          />
        </Card>
      </div>
    );
  }

  _renderFlightModel(model, index, dayStart, withProvider = false) {
    const {
      id,
      pilot_display_name,
      requested_by,
      flight_segment_summaries,
      chargeable_full_names,
      public_notes,
      customer_reference,
    } = model;

    return (
      <div key={index} style={{ padding: '10px' }}>
        <Card
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.5) 0px 1px 6px, rgba(0, 0, 0, 0.5) 0px 1px 4px',
          }}
        >
          <CardHeader
            title={this._renderHeaderTitle(model, dayStart)}
            subtitle={this._renderHeaderSubtitle(model, withProvider)}
            avatar={<Avatar icon={<ActionFlightTakeoffIcon />} />}
            style={{ paddingBottom: 0 }}
          />
          <CardText style={{ padding: 0 }}>
            <Table selectable={false}>
              <TableBody displayRowCheckbox={false}>
                <TableRow style={{ borderBottomWidth: '0' }}>
                  <TableRowColumn style={{ padding: '5px 0 0 12px', width: '48px' }}>
                    Detail
                  </TableRowColumn>
                  <TableRowColumn
                    style={{
                      padding: '5px 0 0 12px',
                      overflow: 'auto',
                      whiteSpace: 'normal',
                    }}
                  >
                    <span>
                      <strong>{'Pilot: '}</strong>
                      {pilot_display_name}
                    </span>
                    {requested_by ? (
                      <span style={{ marginLeft: '10px' }}>
                        <strong>{'Requested by: '}</strong>
                        {requested_by}
                      </span>
                    ) : (
                      ''
                    )}
                    {customer_reference ? (
                      <span style={{ marginLeft: '10px' }}>
                        <strong>{'Customer Reference: '}</strong>
                        {customer_reference}
                      </span>
                    ) : (
                      ''
                    )}
                  </TableRowColumn>
                </TableRow>
                <TableRow style={{ borderBottomWidth: '0' }}>
                  <TableRowColumn style={{ padding: '0 0 0 12px', width: '48px' }}>
                    Flight
                  </TableRowColumn>
                  <TableRowColumn
                    style={{ padding: '0' }}
                    colSpan={2}
                    children={
                      <NestedTable flight_segment_summaries={flight_segment_summaries} />
                    }
                  />
                </TableRow>
                {public_notes ? (
                  <TableRow style={{ borderBottomWidth: '0' }}>
                    <TableRowColumn style={{ padding: '0 0 0 12px', width: '48px' }}>
                      Notes
                    </TableRowColumn>
                    <TableRowColumn style={{ padding: '0 0 0 12px' }}>
                      {public_notes}
                    </TableRowColumn>
                  </TableRow>
                ) : (
                  ''
                )}
              </TableBody>
            </Table>
          </CardText>
        </Card>
      </div>
    );
  }

  _renderModel(model, index, dayStart) {
    const {
      calendar_type,
      provider_id,
      aircraft_id,
      aircraft_provider_id,
      chargeable_ids,
    } = model;
    if (calendar_type === 'flight') {
      if (parseInt(provider_id) === parseInt(this.props.auth_provider_id)) {
        return this._renderFlightModel(model, index, dayStart);
      } else if (
        _intersection(
          get(showRelatedChargeables, parseInt(this.props.auth_provider_id), []),
          chargeable_ids
        ).length > 0
      ) {
        return this._renderFlightModel(model, index, dayStart, true);
      } else if (
        includes(
          get(showBlockedProviders, parseInt(this.props.auth_provider_id), []),
          aircraft_id
        )
      ) {
        return this._renderBlockedModel(model, index, dayStart);
      }
    } else if (calendar_type === 'maintenance') {
      // console.log('render main');
      return this._renderMaintenanceModel(model, index, dayStart);
    }
  }

  _renderDay(index, dayStart, $$dayBookings) {
    if ($$dayBookings.size === 0) {
      return (
        <div key={'day-' + index}>
          <Chip style={{ marginLeft: '10px' }}>
            {dayStart.tz('Pacific/Auckland').format('LL')}
          </Chip>
        </div>
      );
    } else {
      const dayBookings = $$dayBookings.map(($$model, index) => {
        return this._renderModel($$model.toJS(), index, dayStart);
      });
      const dayBookingsJs = compact(dayBookings.toJS());
      if (dayBookingsJs.length > 0) {
        return <div key={'day-' + index}>{dayBookingsJs}</div>;
      } else {
        return (
          <div key={'day-' + index}>
            <Chip style={{ marginLeft: '10px' }}>
              {dayStart.tz('Pacific/Auckland').format('LL')}
            </Chip>
          </div>
        );
      }
    }
  }

  _renderItems() {
    if (this.props.bookingsIsFetching) {
      return (
        <div
          style={{
            margin: '10px',
            height: '80%',
          }}
        >
          <Spinner />
        </div>
      );
    } else if (this.props.$$bookingsDisplayCollection.size === 0) {
      return (
        <div style={{ margin: '10px' }}>
          <p>
            {'No results for ' +
              this.props.MM_bookingsDisplayCollectionStartDate.tz(
                'Pacific/Auckland'
              ).format('MMMM')}
          </p>
        </div>
      );
    } else {
      // console.log(this.props.$$bookingsDisplayCollection.toJS());
      const MM_filterFrom = moment.max(
        this.props.MM_bookingsDisplayCollectionStartDate.tz('Pacific/Auckland').clone(),
        moment().tz('Pacific/Auckland').startOf('day')
      );
      const MM_filterTo = MM_filterFrom.clone()
        .tz('Pacific/Auckland')
        .add(1, 'month')
        .endOf('month');
      // console.log(MM_filterFrom.format());
      // console.log(MM_filterTo.format());
      const $$bookings = this.props.$$bookingsDisplayCollection.filter(($$model) => {
        return (
          moment($$model.get('start_at'))
            .tz('Pacific/Auckland')
            .isSameOrAfter(MM_filterFrom) ||
          $$model.get('calendar_type') === 'maintenance'
        );
      });
      // console.log($$bookings.toJS());
      if ($$bookings.size > 0) {
        const dayRange = moment.range(
          MM_filterFrom.tz('Pacific/Auckland'),
          MM_filterTo.tz('Pacific/Auckland')
          // MM_filterFrom.clone().tz('Pacific/Auckland').add(1, 'month').endOf('month')
        );
        const dayArray = Array.from(dayRange.by('day'));
        return (
          <div style={{ marginTop: '8px' }}>
            {dayArray.map((dayStart, index) => {
              const dayEnd = dayStart.clone().tz('Pacific/Auckland').endOf('day');
              const $$dayBookings = $$bookings.filter(($$model) => {
                return (
                  $$model.get('human_status') !== 'Cancelled' &&
                  moment($$model.get('start_at'))
                    .tz('Pacific/Auckland')
                    .isSameOrBefore(dayEnd) &&
                  moment($$model.get('end_at'))
                    .tz('Pacific/Auckland')
                    .isSameOrAfter(dayStart.tz('Pacific/Auckland'))
                );
              });
              return this._renderDay(index, dayStart, $$dayBookings);
            })}
          </div>
        );
      } else {
        return (
          <div style={{ margin: '10px' }}>
            <p>
              {'No results for ' +
                this.props.MM_bookingsDisplayCollectionStartDate.tz(
                  'Pacific/Auckland'
                ).format('MMMM')}
            </p>
          </div>
        );
      }
    }
  }

  _renderStartAtDateError() {
    if (this.state.start_at_date_error) {
      return (
        <span
          style={{
            display: 'inline-block',
            marginLeft: '10px',
            color: 'rgb(244, 67, 54',
            fontSize: '12px',
          }}
        >
          {this.state.start_at_date_error}
        </span>
      );
    }
  }

  render() {
    return (
      <div style={{ height: this.props.height + 'px', overflow: 'scroll' }}>
        <h2 style={{ paddingLeft: '10px' }}>Bookings</h2>
        <span>
          <span style={{ display: 'inline-block', paddingLeft: '10px' }}>
            <DateTime
              value={this.state.start_at_date}
              dateFormat={'MM/YYYY'}
              timeFormat={false}
              closeOnSelect={true}
              viewMode="months"
              isValidDate={this._isValidDate}
              onChange={this._handleStartAtDateChange}
            />
          </span>
          {this._renderStartAtDateError()}
        </span>
        {this._renderItems()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookingsIsFetching: state.$$bookings.get('isFetching'),
    $$bookings_collection: state.$$bookings.get('collection'),
    $$bookingsDisplayCollection: getBookingsDisplayCollection(state),
    MM_bookingsDisplayCollectionStartDate: moment(
      state.$$bookings.get('displayCollectionStartDate')
    ),
    MM_bookingsCollectionEndDate: moment(state.$$bookings.get('collectionEndDate')),
    auth_provider_id: state.$$auth.get('provider_id'),
  };
}

export default connect(mapStateToProps, {
  bookingsAppend,
  bookingsSetDisplayCollectionStartDate,
})(BookingList);
