import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore, routerActions } from 'react-router-redux';
import { UserAuthWrapper } from 'redux-auth-wrapper';
import createRollbar from './rollbar';
import createStore from './store/saam_store';
import createRoutes from './routes/saam_routes';
import { authLoadSuccess } from './actions/saam_actions';

// import injectTapEventPlugin from 'react-tap-event-plugin';
// injectTapEventPlugin()

const rollbar = createRollbar();
window.Rollbar = rollbar;
const store = createStore();
const history = syncHistoryWithStore(browserHistory, store);
const routes = createRoutes();
const target = document.getElementById('root');

const node = (
  <Provider store={store}>
    <Router history={history} routes={routes} />
  </Provider>
);

let token = localStorage.getItem('token');

if (token && token !== undefined) {
  store.dispatch(authLoadSuccess(token));
}

ReactDOM.render(node, target);
