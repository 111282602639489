import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dimensions from 'react-dimensions';
import MediaQuery from 'react-responsive';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import Spinner from 'react-spinner';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import Paper from 'material-ui/Paper';
import AppBar from 'material-ui/AppBar';
import Drawer from 'material-ui/Drawer';
import IconButton from 'material-ui/IconButton';
import NavigationCloseIcon from 'material-ui/svg-icons/navigation/close';
import IconMenu from 'material-ui/IconMenu';
import NavigationMoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import MenuItem from 'material-ui/MenuItem';
import Snackbar from 'material-ui/Snackbar';

import { List, ListItem, makeSelectable } from 'material-ui/List';
let SelectableList = makeSelectable(List);

import { blue900, blue700 } from 'material-ui/styles/colors';
import LoginBackgroundImage from '../images/login_background.jpg';
require('react-spinner/react-spinner.css');

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: blue900,
    primary2Color: blue700,
  },
});

import { authReset, messageClose } from '../actions/saam_actions';

class App extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
    this._handleDrawerToggle = this._handleDrawerToggle.bind(this);
    this._handleNavigationClick = this._handleNavigationClick.bind(this);
    this._handleAppBarItemTouchTap = this._handleAppBarItemTouchTap.bind(this);
    this._handleSnackBarClose = this._handleSnackBarClose.bind(this);
  }

  _handleDrawerToggle() {
    this.setState({
      drawerOpen: !this.state.drawerOpen,
    });
  }

  _handleNavigationClick(e, route) {
    this.setState({
      drawerOpen: false,
    });
    this.context.router.push(route);
  }

  _handleAppBarItemTouchTap(e, menuItem) {
    this.props.authReset();
    this.context.router.push('/login');
  }

  _renderTitle() {
    if (this.props.isAuthenticated) {
      return 'Advanced Flight - ' + this.props.provider;
    } else {
      return 'Advanced Flight';
    }
  }

  _renderAppBar() {
    return (
      <MediaQuery maxDeviceWidth={414}>
        {(matches) => {
          if (matches) {
            return (
              <AppBar
                title={this._renderTitle()}
                onLeftIconButtonTouchTap={this._handleDrawerToggle}
                iconElementRight={
                  <IconMenu
                    iconButtonElement={
                      <IconButton>
                        <NavigationMoreVertIcon />
                      </IconButton>
                    }
                    targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onItemTouchTap={this._handleAppBarItemTouchTap}
                  >
                    <MenuItem
                      primaryText="Logout"
                      disabled={!this.props.isAuthenticated}
                    />
                  </IconMenu>
                }
              />
            );
          } else {
            return (
              <AppBar
                title={this._renderTitle()}
                showMenuIconButton={false}
                iconElementRight={
                  <IconMenu
                    iconButtonElement={
                      <IconButton>
                        <NavigationMoreVertIcon />
                      </IconButton>
                    }
                    targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onItemTouchTap={this._handleAppBarItemTouchTap}
                  >
                    <MenuItem
                      primaryText="Logout"
                      disabled={!this.props.isAuthenticated}
                    />
                  </IconMenu>
                }
              />
            );
          }
        }}
      </MediaQuery>
    );
  }

  _renderDrawer() {
    return;
    const height = this.props.containerHeight - 64;
    const heightPx = height + 'px';
    return (
      <MediaQuery maxDeviceWidth={414}>
        {(matches) => {
          if (matches) {
            return (
              <Drawer
                containerStyle={{ height: this.props.containerHeight }}
                open={this.state.drawerOpen}
              >
                <AppBar
                  title="Navigation"
                  showMenuIconButton={false}
                  onRightIconButtonTouchTap={this._handleDrawerToggle}
                  iconElementRight={
                    <IconButton>
                      <NavigationCloseIcon />
                    </IconButton>
                  }
                />
                <SelectableList
                  value={this.props.location.pathname}
                  onChange={this._handleNavigationClick}
                >
                  <ListItem value={'/bookings'} primaryText="Bookings" />
                </SelectableList>
              </Drawer>
            );
          } else {
            return (
              <Drawer containerStyle={{ top: '64px', height: heightPx }} docked={true}>
                <SelectableList
                  value={this.props.location.pathname}
                  onChange={this._handleNavigationClick}
                >
                  <ListItem value={'/bookings'} primaryText="Bookings" />
                </SelectableList>
              </Drawer>
            );
          }
        }}
      </MediaQuery>
    );
  }

  _renderChildren() {
    const height = this.props.containerHeight - 64;
    const heightPx = height + 'px';
    return (
      <MediaQuery maxDeviceWidth={414}>
        {(matches) => {
          if (matches) {
            return (
              <div style={{ height: heightPx }}>
                <Paper style={{ margin: '10px 10px 10px 10px' }}>
                  {React.Children.map(this.props.children, (child) =>
                    React.cloneElement(child, {
                      height: height - 20,
                    })
                  )}
                </Paper>
              </div>
            );
          } else {
            return (
              <div style={{ height: heightPx }}>
                <Paper style={{ margin: '10px 10px 10px 10px' }}>
                  {React.Children.map(this.props.children, (child) =>
                    React.cloneElement(child, {
                      height: height - 20,
                    })
                  )}
                </Paper>
              </div>
            );
          }
        }}
      </MediaQuery>
    );
  }

  _renderLoginChildren() {
    const height = this.props.containerHeight - 64;
    const heightPx = height + 'px';
    return (
      <div style={{ height: heightPx }}>
        <div
          style={{
            display: 'table',
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        >
          <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <div style={{ margin: '0 auto', width: '302px' }}>
              <Paper style={{ padding: '20px' }}>
                {React.Children.map(this.props.children, (child) =>
                  React.cloneElement(child, {
                    height: height - 20,
                  })
                )}
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _handleSnackBarClose() {
    this.props.messageClose();
  }

  _renderSnackBar() {
    return (
      <Snackbar
        open={this.props.snackBarOpen}
        message={this.props.snackBarMessage}
        autoHideDuration={3000}
        onRequestClose={this._handleSnackBarClose}
      />
    );
  }

  render() {
    if (!this.props.isAuthenticated) {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div
            style={{
              backgroundImage: 'url(' + LoginBackgroundImage + ')',
              width: '100%',
              height: '100%',
            }}
          >
            {this._renderLoginChildren()}
            {this._renderSnackBar()}
          </div>
        </MuiThemeProvider>
      );
    } else {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div>
            <header>{this._renderAppBar()}</header>
            {this._renderDrawer()}
            {this._renderChildren()}
            {this._renderSnackBar()}
          </div>
        </MuiThemeProvider>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    snackBarOpen: !!state.$$message.get('message'),
    snackBarMessage: state.$$message.get('message'),
    isAuthenticated: state.$$auth.get('isAuthenticated'),
    provider: state.$$auth.get('provider'),
  };
}

App = Dimensions()(App);
App = DragDropContext(HTML5Backend)(App);
export default connect(mapStateToProps, { authReset, messageClose })(App);
