import rollbar from 'rollbar-browser/dist/rollbar.umd.nojson';

export default () => {
  if (process.env.NODE_ENV === 'production') {
    return rollbar.init({
      accessToken: process.env.ROLLBAR_CLIENT_ACCESS_TOKEN, // eslint-disable-line no-undef
      captureUncaught: true,
      payload: {
        environment: process.env.NODE_ENV,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: process.env.GIT_REVISION, // eslint-disable-line no-undef
            guess_uncaught_frames: true
          }
        }
      }
    })
  }
}

