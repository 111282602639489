import React from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

const NestedTable = ({ flight_segment_summaries }) => (
  <Table selectable={false}>
    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
      <TableRow>
        <TableHeaderColumn style={{textAlign: 'right', padding: '0 0 0 12px', width:"48px"}}>Start</TableHeaderColumn>
        <TableHeaderColumn style={{padding: '0 0 0 12px', width:"48px"}}>From</TableHeaderColumn>
        <TableHeaderColumn style={{padding: '0 0 0 12px', width:"48px"}}>To</TableHeaderColumn>
        <TableHeaderColumn style={{padding: '0 0 0 12px'}}>Pax</TableHeaderColumn>
      </TableRow>
    </TableHeader>
    <TableBody displayRowCheckbox={false}>
      {
        flight_segment_summaries.map((flight_segment_summary, index) => {
          const {
            start_at,
            start_location,
            end_location,
            pax
          } = flight_segment_summary

          return (
            <TableRow key={'fss' + index}>
              <TableRowColumn style={{textAlign: 'right', padding: '0 0 0 12px', width:"48px"}}>
                {start_at}
              </TableRowColumn>
              <TableRowColumn style={{padding: '0 0 0 12px', width:"48px"}}>
                {start_location}
              </TableRowColumn>
              <TableRowColumn style={{padding: '0 0 0 12px', width:"48px"}}>
                {end_location}
              </TableRowColumn>
              <TableRowColumn style={{padding: '0 0 0 12px'}}>
                {pax}
              </TableRowColumn>
            </TableRow>
          )
        })
      }
    </TableBody>
  </Table>
);

export default NestedTable;
