/* eslint no-console: 0 */

export default function logger({ getState }) {
  return next => action => {
    // console.log('will dispatch', action);
    const result = next(action);
    const immutableState = getState();
    // console.log('state after dispatch', JSON.stringify(immutableState));
    return result;
  };
}
