import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';

import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import LoginLogo from '../images/login_logo_255x67.jpg';

import { authLoad } from '../actions/saam_actions';

class Login extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const redirectRoute = this.props.location.query.redirect || '/bookings';
    this.state = {
      hostname: '',
      username: '',
      password: '',
      redirectTo: redirectRoute,
    };
    this._handleUsernameChange = this._handleUsernameChange.bind(this);
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    let hostname = window.location.hostname.replace(/^www./, '');
    this.setState({
      hostname: hostname,
      username:
        ['localhost', 'customer.saam.co.nz', 'customer.lvh.me'].indexOf(hostname) > -1
          ? ''
          : hostname,
    });
  }

  _handleSubmit(e) {
    e.preventDefault();
    this.props.authLoad(this.state.username, this.state.password, this.state.redirectTo);
  }

  _handleUsernameChange(e, index, value) {
    value = value || e.target.value || '';
    this.setState({
      username: value,
    });
  }

  _handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  _renderUsername() {
    if (
      ['localhost', 'customer.saam.co.nz', 'customer.lvh.me'].indexOf(
        this.state.hostname
      ) > -1
    ) {
      return (
        <SelectField
          floatingLabelText="Provider"
          value={this.state.username}
          onChange={this._handleUsernameChange}
        >
          <MenuItem value="" primaryText="" />
          <MenuItem value="kcheli.co.nz" primaryText="KC Charter" />
          <MenuItem value="gmiheli.co.nz" primaryText="Upper Case" />
          <MenuItem value="145.co.nz" primaryText="HGF Holdings" />
          <MenuItem value="tiheli.co.nz" primaryText="Tara Iti" />
        </SelectField>
      );
      // } else {
      //   return (
      //     <TextField
      //       floatingLabelText="Username"
      //       value={this.state.username}
      //       onChange={this._handleUsernameChange}
      //     />
      //   )
    }
  }

  render() {
    return (
      <div>
        <input style={{ display: 'none' }} type="text" name="fakeusernameremembered" />
        <input
          style={{ display: 'none' }}
          type="password"
          name="fakepasswordremembered"
        />
        <div style={{ marginBottom: '8px' }}>
          <img src={LoginLogo} />
        </div>
        <form onSubmit={this._handleSubmit}>
          {this._renderUsername()}
          <TextField
            floatingLabelText="Password"
            type="password"
            value={this.state.duration}
            onChange={this._handlePasswordChange}
          />
          <RaisedButton
            style={{ marginTop: '24px' }}
            type="submit"
            label="Submit"
            primary={true}
            fullWidth={true}
            disabled={
              this.props.isAuthenticating ||
              this.state.username.length === 0 ||
              this.state.password.length === 0
            }
          />
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticating: state.$$auth.get('isAuthenticating'),
  };
}

export default connect(mapStateToProps, { authLoad })(Login);
