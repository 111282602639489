import { compose, createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import loggerMiddleware from '../lib/loggerMiddleware'
import { reducers  } from '../reducers'
import { browserHistory } from 'react-router'
import { routerReducer, routerMiddleware } from 'react-router-redux'

export default () => {
  const reducer = combineReducers({
    ...reducers,
    routing: routerReducer
  })

  const store = createStore(
    reducer,
    applyMiddleware(
      routerMiddleware(browserHistory),
      thunkMiddleware,
      loggerMiddleware
    )
  )
  return store
}
